import { getApolloClient } from '@confluence/graphql';

import { StarredSpacesQuery } from './SpacesListQueries.graphql';
import { SpacesListSpaceFragment } from './SpacesListSpaceFragment.fragment';
import type { SpacesListSpaceFragment as SpaceFragmentData } from './__types__/SpacesListSpaceFragment';
import type { StarredSpacesQuery as StarredSpacesQueryData } from './__types__/StarredSpacesQuery';

export const updateStarredSpacesList = (
	spaceId: string,
	isStarred: boolean,
	includeAlias: boolean,
) => {
	const apolloClient = getApolloClient();

	const space = apolloClient.readFragment<SpaceFragmentData>({
		id: `Space:${spaceId}`,
		fragment: SpacesListSpaceFragment,
		variables: { includeAlias },
	});

	const starredSpaces =
		apolloClient.readQuery<StarredSpacesQueryData>({
			query: StarredSpacesQuery,
			variables: { includeAlias },
		})?.spaces?.nodes || [];

	let newStarredSpaces;
	if (isStarred) {
		newStarredSpaces = [...starredSpaces, space];
		newStarredSpaces.sort((a, b) => (!a?.name || !b?.name ? 0 : a.name.localeCompare(b.name)));
	} else {
		newStarredSpaces = starredSpaces.filter((s) => s?.id !== spaceId);
	}

	apolloClient.writeQuery({
		query: StarredSpacesQuery,
		variables: { includeAlias },
		data: {
			spaces: { nodes: newStarredSpaces, __typename: 'PaginatedSpaceList' },
		},
	});
};
