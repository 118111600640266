import { useEffect, useCallback } from 'react';

const ADD_SPACE_LINK = 'adg3.ConfigurableSpaceSidebar.addSpaceLink';
const REMOVE_SPACE_LINK = 'adg3.ConfigurableSpaceSidebar.removeSpaceLink';

export const useSidebarConfigurationListener = (
	addSpaceLink, //arg functions are passed as refs as destructure from useMutation
	removeSpaceLink,
) => {
	const addSpaceLinkInner = useCallback(
		(e: any) => {
			const { spaceKey, id } = e.detail;
			addSpaceLink({ variables: { spaceKey, id } });
		},
		[addSpaceLink],
	);

	const removeSpaceLinkInner = useCallback(
		(e: any) => {
			const { spaceKey, id } = e.detail;
			removeSpaceLink({ variables: { spaceKey, id } });
		},
		[removeSpaceLink],
	);

	useEffect(() => {
		window.addEventListener(ADD_SPACE_LINK, addSpaceLinkInner);
		window.addEventListener(REMOVE_SPACE_LINK, removeSpaceLinkInner);

		return () => {
			window.removeEventListener(ADD_SPACE_LINK, addSpaceLinkInner);
			window.removeEventListener(REMOVE_SPACE_LINK, removeSpaceLinkInner);
		};
	}, [addSpaceLinkInner, removeSpaceLinkInner]);
};
