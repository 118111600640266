/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum PageCardInPageTreeHoverPreference {
  NO_OPTION_SELECTED = "NO_OPTION_SELECTED",
  NO_SHOW_PAGECARD = "NO_SHOW_PAGECARD",
  SHOW_PAGECARD = "SHOW_PAGECARD",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HoverPageCardsOptStatusQuery
// ====================================================

export interface HoverPageCardsOptStatusQuery_userPreferences {
  shouldShowCardOnPageTreeHover: PageCardInPageTreeHoverPreference;
}

export interface HoverPageCardsOptStatusQuery {
  userPreferences: HoverPageCardsOptStatusQuery_userPreferences;
}
