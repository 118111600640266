import { useMemo, useState, useCallback } from 'react';

import {
	confluenceLocalStorageInstance as localStorage,
	PERSISTED_KEYS_ON_SERVER,
} from '@confluence/storage-manager';

export const useSpacePersistentExpansion = (sectionName: string, collapsedByDefault = false) => {
	const [isExpandedManually, setIsExpandedManually] = useState<boolean | undefined>();

	const isDefaultExpanded = useMemo(
		() => getExpandedStateBySpace(sectionName, collapsedByDefault),
		[sectionName, collapsedByDefault],
	);

	const hasManualExpansion = isExpandedManually !== undefined;
	const isExpanded = hasManualExpansion ? isExpandedManually : isDefaultExpanded;

	const onExpansionToggle = useCallback(
		(newIsExpanded: boolean) => {
			setIsExpandedManually(newIsExpanded);
			updateExpandedStateBySpace(sectionName, newIsExpanded);
		},
		[sectionName],
	);

	return {
		isExpanded,
		onExpansionToggle,
	};
};

const getExpandedStateBySpace = (sectionName: string, collapsedByDefault: boolean) => {
	if (
		localStorage.doesContain(PERSISTED_KEYS_ON_SERVER.PERSISTED_SPACE_NAV_SECTIONS_EXPANDED_STATES)
	) {
		const sideNavExpansionStates = localStorage.getItem(
			PERSISTED_KEYS_ON_SERVER.PERSISTED_SPACE_NAV_SECTIONS_EXPANDED_STATES,
		);
		if (typeof sideNavExpansionStates[sectionName] === 'boolean') {
			return sideNavExpansionStates[sectionName];
		}
	}
	return !collapsedByDefault;
};

const updateExpandedStateBySpace = (sectionName: string, isExpanded: boolean) => {
	const sideNavExpansionStates =
		localStorage.getItem(PERSISTED_KEYS_ON_SERVER.PERSISTED_SPACE_NAV_SECTIONS_EXPANDED_STATES) ||
		{};
	localStorage.setItem(PERSISTED_KEYS_ON_SERVER.PERSISTED_SPACE_NAV_SECTIONS_EXPANDED_STATES, {
		...sideNavExpansionStates,
		[sectionName]: isExpanded,
	});
};
