import { LoadableLazy } from '@confluence/loadable';

export const PersistentInvitePeopleButton = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-PersistentInvitePeopleButton" */ './PersistentInvitePeopleButton'
			)
		).PersistentInvitePeopleButton,
});

export { InviteButtonComponent } from './PersistentInvitePeopleButtonComponent';
