import gql from 'graphql-tag';

export const UserPermissionFragment = gql`
	fragment UserPermissionFragment on Query {
		user(current: true) {
			id
			confluence {
				permissionType
			}
		}
	}
`;
