import { LoadableAfterPaint } from '@confluence/loadable';

export const SpacePersistentCollapsibleSectionLoader = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-SpacePersistentCollapsibleSection" */ '../src/PersistentCollapsibleSection'
			)
		).SpacePersistentCollapsibleSection,
});
