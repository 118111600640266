import { UNKNOWN } from './viewPageTransitionSourceConstants';

type TransitionSource = string;

let transitionSource: TransitionSource | undefined;

export const setViewTransitionSource = (source: TransitionSource) => {
	transitionSource = source;
};

export const getViewTransitionSource = () => {
	return transitionSource || UNKNOWN;
};

export const resetViewTransitionSource = () => {
	transitionSource = undefined;
};
