import { styled, css } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import Button from '@atlaskit/button/standard-button';
import { N500, N80 } from '@atlaskit/theme/colors';

const buttonStyle = css({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	height: '32px',
	width: '32px',
	border: 'none',
	borderRadius: '3px',
	background: token('color.background.neutral.subtle', 'transparent'),
	cursor: 'pointer',
	'&:hover': {
		background: token('color.background.neutral.subtle.hovered', 'rgba(9, 30, 66, 0.08)'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
export const FilledIconButton = styled.button(buttonStyle);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
export const UnfilledIconButton = styled.button(buttonStyle, {
	color: 'var(--action-button-color)',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&:hover': {
		color: token('color.icon', N500),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HoverButton = styled.button({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> span': {
		color: `var(--action-button-color, ${token('color.icon.subtle', N80)})`,
	},
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'> span': {
			color: token('color.icon', N500),
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HiddenAction = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> *': {
		opacity: 'var(--show-action)',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> *:focus': {
		opacity: 1,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
export const HiddenActionButton = styled.div(buttonStyle, {
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> *': {
		opacity: 'var(--show-action)',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> *:focus': {
		opacity: 1,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ConditionalHiddenAction = styled.div(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isHidden }: { isHidden?: boolean }) => ({
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'> *': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			opacity: isHidden ? 'var(--show-action)' : '1',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'> *:focus': {
			opacity: '1',
		},
	}),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ConditionalSubtleHoverButton = styled(Button)<{
	isSubtle?: boolean;
}>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		props.isSubtle && {
			'> *': {
				color: `var(--action-button-color, ${token('color.icon.subtle', N80)})`,
			},
			'&:hover': {
				'> *': {
					color: token('color.icon', N500),
				},
			},
		},
);
