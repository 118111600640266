import type { MouseEvent, KeyboardEvent } from 'react';
import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import Popup from '@atlaskit/popup';
import ShowMoreIcon from '@atlaskit/icon/utility/show-more-horizontal';
import SettingsIcon from '@atlaskit/icon/core/settings';
import ArchiveIcon from '@atlaskit/icon/core/archive-box';
import TrashIcon from '@atlaskit/icon/core/delete';
import { LinkItem, MenuGroup, Section } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';
import { Text } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useSessionData } from '@confluence/session-data';
import { SPACE_SETTINGS_TRASH_SPACE, SPACE_SETTINGS_ARCHIVE_SPACE } from '@confluence/named-routes';
import { SpaceWatchButton } from '@confluence/action-buttons/entry-points/spaceWatch';
import { SpaceStarMenuItem } from '@confluence/action-buttons/entry-points/spaceStar';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';

import { useSpaceSettingsLinkUtils } from './SpaceSettingsLink';

type SpaceMoreActionsMenuProps = {
	spaceId: string;
	spaceKey: string;
	isStarred: boolean;
	isWatched: boolean;
	isSpaceAdmin: boolean;
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	isSpaceArchived?: boolean;
};

export const SpaceMoreActionsMenu = (props: SpaceMoreActionsMenuProps) => {
	const { isLoggedIn } = useSessionData();
	const isNav4Enabled = useIsNav4Enabled();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	if (!isLoggedIn || !isNav4Enabled) {
		return null;
	}

	const onClick = (e: MouseEvent) => {
		e.stopPropagation();
		e.preventDefault();
		props.setIsOpen(!props.isOpen);
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'spaceMoreActionsMenu',
				source: 'spaceNavigation',
				attributes: {
					navigationLayer: 'container',
					navigationContainer: 'sidebar',
					navVersion: '4',
					componentType: 'fixedItemButton',
					itemId: 'meatballDropdown',
					isSpaceAdmin: props.isSpaceAdmin,
				},
			},
		}).fire();
	};

	const onMenuItemClicked =
		(selection: string) => (e: KeyboardEvent<HTMLElement> | MouseEvent<HTMLElement>) => {
			e.stopPropagation();
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'spaceMoreActionsMenuItem',
					source: 'spaceMoreActionsMenu',
					attributes: {
						navigationLayer: 'container',
						navigationContainer: 'sidebar',
						navVersion: '4',
						componentType: 'fixedItemAction',
						itemId: selection,
						isSpaceAdmin: props.isSpaceAdmin,
					},
				},
			}).fire();
			props.setIsOpen(false);
		};

	return (
		<Popup
			isOpen={props.isOpen}
			onClose={() => props.setIsOpen(false)}
			content={() => (
				<SpaceMoreActionsMenuContent {...props} onMenuItemClicked={onMenuItemClicked} />
			)}
			trigger={(triggerProps) => (
				<IconButton
					icon={ShowMoreIcon}
					label={<FormattedMessage {...i18n.moreActions} />}
					onClick={onClick}
					spacing="compact"
					appearance="subtle"
					{...triggerProps}
				/>
			)}
			placement="bottom-start"
		/>
	);
};

type SpaceMoreActionsMenuContentProps = {
	onMenuItemClicked: (
		selection: string,
	) => (e: KeyboardEvent<HTMLElement> | MouseEvent<HTMLElement>) => void;
	isSpaceArchived?: boolean;
} & SpaceMoreActionsMenuProps;

const SpaceMoreActionsMenuContent = ({
	spaceId,
	spaceKey,
	isStarred,
	isWatched,
	isSpaceAdmin,
	isSpaceArchived,
	onMenuItemClicked,
}: SpaceMoreActionsMenuContentProps) => {
	const { getHref, fireClickedEvent, isExperimentTest } = useSpaceSettingsLinkUtils({ spaceKey });

	return (
		<MenuGroup minWidth="240px">
			<Section>
				<SpaceWatchButton spaceId={spaceId} isWatched={isWatched} inMenu />
				<SpaceStarMenuItem spaceId={spaceId} spaceKey={spaceKey} isStarred={isStarred} />
			</Section>
			{!isExperimentTest && (
				<Section hasSeparator>
					<LinkItem
						href={getHref({ isSpaceAdmin })}
						iconBefore={<SettingsIcon label="" color="currentColor" />}
						onClick={(e) => {
							fireClickedEvent({ analyticsSource: 'spaceMoreActionsMenu' });
							onMenuItemClicked('spaceSettings')(e);
						}}
					>
						<FormattedMessage {...i18n.spaceSettings} />
					</LinkItem>
				</Section>
			)}
			{isSpaceAdmin && (
				<Section hasSeparator>
					<LinkItem
						href={SPACE_SETTINGS_ARCHIVE_SPACE.toUrl({ spaceKey })}
						iconBefore={<ArchiveIcon label="" color="currentColor" />}
						onClick={onMenuItemClicked('spaceArchive')}
					>
						{isSpaceArchived ? (
							<FormattedMessage {...i18n.restoreSpace} />
						) : (
							<FormattedMessage {...i18n.archiveSpace} />
						)}
					</LinkItem>
					<LinkItem
						href={SPACE_SETTINGS_TRASH_SPACE.toUrl({ spaceKey })}
						iconBefore={<TrashIcon label="" color={token('color.text.danger')} />}
						onClick={onMenuItemClicked('spaceDelete')}
					>
						<Text color="color.text.danger">
							<FormattedMessage {...i18n.trashSpace} />
						</Text>
					</LinkItem>
				</Section>
			)}
		</MenuGroup>
	);
};

const i18n = defineMessages({
	moreActions: {
		id: 'side-navigation.space-navigation.more-actions',
		defaultMessage: 'More actions',
		description:
			'Accessible label text for the ... button which opens a menu of additional actions for a space. Appears next to the space title.',
	},
	spaceSettings: {
		id: 'side-navigation.space-navigation.space-settings',
		defaultMessage: 'Space settings',
		description: 'Menu item to navigate to space settings. Appears in the space more actions menu.',
	},
	archiveSpace: {
		id: 'side-navigation.space-navigation.archive-space',
		defaultMessage: 'Archive space',
		description:
			'Menu item to archive a space. Links to the archive space settings page. Appears in the space more actions menu.',
	},
	restoreSpace: {
		id: 'side-navigation.space-navigation.restore-space',
		defaultMessage: 'Restore space',
		description:
			'Menu item to restore an archived space. Links to the archive space settings page. Appears in the space more actions menu.',
	},
	trashSpace: {
		id: 'side-navigation.space-navigation.trash-space',
		defaultMessage: 'Delete space',
		description:
			'Menu item to trash a space. Links to the trash space settings page. Appears in the space more actions menu. For unclear reasons, a decision was made to use the word "Delete" isntead of "Trash". This button does not permanently delete the space, it allows the user to move it to the trash.',
	},
});
