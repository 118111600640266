/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { ListItem } from '../../components/list-item';

/**
 * __Menu list item__
 *
 * A [list item](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/li) with visual styles removed.
 */
export const MenuListItem = ListItem;
