import type { ComponentType, ReactNode } from 'react';
import React, { useEffect } from 'react';
import type { ApolloError } from 'apollo-client';

import { withFlags } from '@confluence/flags';
import type { FlagsStateContainer } from '@confluence/flags';
import { ErrorDisplay } from '@confluence/error-boundary';

type ActionErrorFlagProps = {
	title: ReactNode;
	description: ReactNode;
	error: ApolloError;
};

export const ActionErrorFlag: ComponentType<ActionErrorFlagProps> = withFlags(
	({ flags, title, description, error }: ActionErrorFlagProps & { flags: FlagsStateContainer }) => {
		useEffect(() => {
			void flags.showErrorFlag({
				id: 'action-button-error',
				title,
				description,
			});
		}, [title, description, flags]);

		return <ErrorDisplay error={error} />;
	},
);
