import React from 'react';

export const DatabasesNavigationLogo = () => (
	<svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="52" height="52" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M43 8H9C8.44772 8 8 8.44772 8 9V43C8 43.5523 8.44772 44 9 44H43C43.5523 44 44 43.5523 44 43V9C44 8.44772 43.5523 8 43 8ZM9 4C6.23858 4 4 6.23858 4 9V43C4 45.7614 6.23858 48 9 48H43C45.7614 48 48 45.7614 48 43V9C48 6.23858 45.7614 4 43 4H9Z"
			fill="currentColor"
		/>
		<rect
			x="24.5"
			y="14.5"
			width="14"
			height="5"
			rx="2.5"
			fill="currentColor"
			stroke="currentColor"
		/>
		<rect x="24" y="23" width="15" height="6" rx="3" fill="currentColor" />
		<rect x="24" y="32" width="15" height="6" rx="3" fill="currentColor" />
		<rect x="13" y="14" width="8" height="6" rx="3" fill="currentColor" />
		<rect x="13" y="23" width="8" height="6" rx="3" fill="currentColor" />
		<rect x="13" y="32" width="8" height="6" rx="3" fill="currentColor" />
	</svg>
);
