import { createContext, useContext } from 'react';

import invariant from 'tiny-invariant';

type onExpansionToggle = (isExpanded: boolean) => void;

/**
 * A context for storing the isExpanded value of the ExpandableMenuItem.
 */
export const IsExpandedContext = createContext<boolean | null>(null);

/**
 * A context for storing a function that sets isExpanded value of the ExpandableMenuItem.
 */
export const SetIsExpandedContext = createContext<((value: boolean) => void) | null>(null);

/**
 * A context for storing the isChildSelected value of the ExpandableMenuItem.
 */
export const IsChildSelectedContext = createContext<boolean | null>(null);

/**
 * A context for storing a function that triggers when isExpanded value of the ExpandableMenuItem is changed.
 */
export const OnExpansionToggleContext = createContext<onExpansionToggle | null>(null);

/**
 * A context for storing the level value of the ExpandableMenuItem.
 */
export const LevelContext = createContext(0);

export const useIsExpanded = (): boolean => {
	const context = useContext(IsExpandedContext);
	invariant(context !== null, 'useIsExpanded must be used within an ExpandableMenuItem');
	return context;
};

export const useSetIsExpanded = (): ((value: boolean) => void) => {
	const context = useContext(SetIsExpandedContext);
	invariant(context !== null, 'useSetIsExpanded must be used within an ExpandableMenuItem');
	return context;
};

export const useIsChildSelected = (): boolean => {
	const context = useContext(IsChildSelectedContext);
	invariant(context !== null, 'useIsChildSelected must be used within an ExpandableMenuItem');
	return context;
};

export const useOnExpansionToggle = (): onExpansionToggle | null =>
	useContext(OnExpansionToggleContext);

export const useLevel = (): number => useContext(LevelContext);
