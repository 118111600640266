import gql from 'graphql-tag';

// space pages and space blogs will share the same page card appearance user preference under "globalPageCardAppearancePreference"

export const SpaceBlogsAppearanceMutation = gql`
	mutation SpaceBlogsAppearanceMutation($persistenceOption: PagesDisplayPersistenceOption!) {
		updateUserPreferences(
			userPreferences: { globalPageCardAppearancePreference: $persistenceOption }
		) {
			globalPageCardAppearancePreference
		}
	}
`;

export const SpaceBlogsAppearancePersistenceQuery = gql`
	query SpaceBlogsAppearancePersistenceQuery {
		userPreferences {
			globalPageCardAppearancePreference
		}
	}
`;
