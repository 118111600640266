import type { FC } from 'react';

export const SSR_PARTIAL_COMPONENT = {
	AdminAnnouncementBanner: 'AdminAnnouncementBanner',
	PageTree: 'PageTree',
	AnalyticsByLine: 'AnalyticsByLine',
} as const;

let ssrPartialSuccess: Partial<Record<keyof typeof SSR_PARTIAL_COMPONENT, boolean>> | null = null;

export function setSSRPartialSuccess(name: keyof typeof SSR_PARTIAL_COMPONENT) {
	if (process.env.REACT_SSR) {
		if (!ssrPartialSuccess) {
			ssrPartialSuccess = {};
		}
		ssrPartialSuccess[name] = true;
	}
}

export const SetSSRPartialSuccess: FC<{ name: keyof typeof SSR_PARTIAL_COMPONENT }> = ({
	name,
}) => {
	setSSRPartialSuccess(name);
	return null;
};

export function checkSSRPartialSuccess(name: keyof typeof SSR_PARTIAL_COMPONENT) {
	if (!ssrPartialSuccess) {
		ssrPartialSuccess = window.__SSR_PARTIAL_SUCCESS__ || {};
	}

	return Boolean(ssrPartialSuccess![name]);
}

export function getSSRPartialSuccess() {
	return ssrPartialSuccess;
}

export function clearSSRPartialSuccess() {
	ssrPartialSuccess = null;
}
