/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum SpaceSidebarLinkType {
  EXTERNAL_LINK = "EXTERNAL_LINK",
  FORGE = "FORGE",
  PINNED_ATTACHMENT = "PINNED_ATTACHMENT",
  PINNED_BLOG_POST = "PINNED_BLOG_POST",
  PINNED_PAGE = "PINNED_PAGE",
  PINNED_SPACE = "PINNED_SPACE",
  PINNED_USER_INFO = "PINNED_USER_INFO",
  WEB_ITEM = "WEB_ITEM",
}

export interface BulkUpdateMainSpaceSidebarLinksInput {
  hidden: boolean;
  id?: string | null;
  linkIdentifier?: string | null;
  type?: SpaceSidebarLinkType | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SideNavigationBulkUpdateMainSpaceSidebarLinks
// ====================================================

export interface SideNavigationBulkUpdateMainSpaceSidebarLinks_bulkUpdateMainSpaceSidebarLinks {
  id: string;
}

export interface SideNavigationBulkUpdateMainSpaceSidebarLinks {
  bulkUpdateMainSpaceSidebarLinks: (SideNavigationBulkUpdateMainSpaceSidebarLinks_bulkUpdateMainSpaceSidebarLinks | null)[];
}

export interface SideNavigationBulkUpdateMainSpaceSidebarLinksVariables {
  input: (BulkUpdateMainSpaceSidebarLinksInput | null)[];
  spaceKey: string;
}
