import gql from 'graphql-tag';

export const MarkFeatureDiscoveredMutation = gql`
	mutation MarkFeatureDiscoveredMutation($featureKey: String!, $pluginKey: String!) {
		markFeatureDiscovered(featureKey: $featureKey, pluginKey: $pluginKey) {
			pluginKey
			featureKey
		}
	}
`;
