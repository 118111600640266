import gql from 'graphql-tag';

export const PersistenceQuery = gql`
	query SpaceViewsPersistenceQuery($spaceKey: String!) {
		userPreferences {
			spaceViewsPersistence(spaceKey: $spaceKey)
		}
	}
`;

export const PersistenceMutation = gql`
	mutation SpaceViewsPersistenceMutation(
		$spaceKey: String!
		$persistenceOption: SpaceViewsPersistenceOption!
	) {
		updateUserPreferences(
			userPreferences: {
				spaceViewsPersistence: { spaceKey: $spaceKey, persistenceOption: $persistenceOption }
			}
		) {
			spaceViewsPersistence(spaceKey: $spaceKey)
		}
	}
`;
