import { useRef, useState, useCallback, useContext } from 'react';

import {
	SPACE_VIEWS_HOVER_PAGE_CARD_EXPERIENCE,
	ExperienceTrackerContext,
	ExperienceTimeout,
} from '@confluence/experience-tracker';

import { useHoverPageCardsOptStatus } from './useHoverPageCardsOptStatus';

export const HOVER_DELAY_TIME = 300;
export const CLOSE_DELAY_TIME = 400;

export type HoverPageCardProps = {
	closeAllHoverPageCards: () => void;
	startCloseHoverPageCardTimer: () => void;
	clearHoverPageCardTimers: () => void;
	showHoverPageCardForItem: {
		itemID: string | null;
		trigger: React.RefObject<HTMLDivElement> | null;
	};
	isHoverPageCardOptedIn: boolean;
	onEnterHoverTarget: (params: {
		itemID: string;
		isQuickActionsFocused: boolean;
		hoverPageCardTriggerRef: React.RefObject<HTMLDivElement>;
		isContextualCreateFocused?: boolean;
	}) => void;
	onLeaveHoverTarget: () => void;
	onBlurHoverTarget: (e: any) => void;
};

/**
 * A hook to handle all refs required to open and close page card popups in the sidebar.
 */
export const useHoverPageCard = (source: string): HoverPageCardProps => {
	const experienceTracker = useContext(ExperienceTrackerContext);

	const { isHoverPageCardOptedIn } = useHoverPageCardsOptStatus();

	// the tree item id and the trigger should be grouped into one state as the trigger always corresponds with the itemID
	// this prevents unnecessary rerenders of the hover page card
	const [showHoverPageCardForItem, setShowHoverPageCardForItem] = useState<{
		itemID: string | null;
		trigger: React.RefObject<HTMLDivElement> | null;
	}>({ itemID: null, trigger: null });

	const openSidebarPopupTimerRef = useRef<NodeJS.Timeout | null>(null);
	const closeSidebarPopupTimerRef = useRef<NodeJS.Timeout | null>(null);

	/**
	 * Cancel the open sidebar popup cards timer
	 */
	const clearOpenSidebarPopupTimer = useCallback(() => {
		if (openSidebarPopupTimerRef?.current) {
			clearTimeout(openSidebarPopupTimerRef.current);
			openSidebarPopupTimerRef.current = null;
		}
	}, [openSidebarPopupTimerRef]);

	/**
	 * Cancel the close sidebar popup cards timer
	 */
	const clearCloseSidebarPopupTimer = useCallback(() => {
		if (closeSidebarPopupTimerRef.current) {
			clearTimeout(closeSidebarPopupTimerRef?.current);
			closeSidebarPopupTimerRef.current = null;
		}
	}, [closeSidebarPopupTimerRef]);

	/**
	 * On mouse enter/focus of the hover target, create a hover card for that itemId
	 * Start the timer which will open the popup if the user hovers on the tree item for HOVER_DELAY_TIME.
	 * Clears any closePageCard timers since we don't need to close the popup, we can just replace the showHoverPageCardForItem.
	 * This is called onMouseOver of page tree items.
	 */
	const onEnterHoverTarget = useCallback(
		({ itemID, isQuickActionsFocused, hoverPageCardTriggerRef, isContextualCreateFocused }) => {
			clearCloseSidebarPopupTimer();
			// only show a hover card if it matches to conditions to show it and it isn't already showing a card for the same itemID
			if (
				isHoverPageCardOptedIn &&
				!isQuickActionsFocused &&
				!isContextualCreateFocused &&
				showHoverPageCardForItem.itemID !== itemID
			) {
				if (openSidebarPopupTimerRef.current === null) {
					openSidebarPopupTimerRef.current = setTimeout(() => {
						// this setstate is the one and only line that causes SpaceHoverPageCard to rerender
						setShowHoverPageCardForItem({
							itemID,
							trigger: hoverPageCardTriggerRef,
						});
						experienceTracker.start({
							name: SPACE_VIEWS_HOVER_PAGE_CARD_EXPERIENCE,
							timeout: ExperienceTimeout.SPACE_VIEWS,
							attributes: {
								source,
							},
						});
						openSidebarPopupTimerRef.current = null;
					}, HOVER_DELAY_TIME);
				}
			}
		},
		// adding showHoverPageCardForItem into the dependency array causes the entire page tree to rerender on any item hover.
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[clearCloseSidebarPopupTimer, experienceTracker, isHoverPageCardOptedIn, source],
	);

	/**
	 * Closes all popups after CLOSE_DELAY_TIME if the closeSidebarPopupTimerRef timer has not been cleared before then
	 * Called on mouse leave of a page tree item
	 */
	const startCloseHoverPageCardTimer = useCallback(() => {
		experienceTracker.abort({
			name: SPACE_VIEWS_HOVER_PAGE_CARD_EXPERIENCE,
			reason: 'Popup closed',
		});
		clearOpenSidebarPopupTimer();
		if (closeSidebarPopupTimerRef.current === null) {
			closeSidebarPopupTimerRef.current = setTimeout(() => {
				setShowHoverPageCardForItem({ itemID: null, trigger: null });
				closeSidebarPopupTimerRef.current = null;
			}, CLOSE_DELAY_TIME);
		}
	}, [experienceTracker, clearOpenSidebarPopupTimer]);

	/**
	 * Clear all the timers, called when the mouse reenters
	 */
	const clearHoverPageCardTimers = useCallback(() => {
		clearOpenSidebarPopupTimer();
		clearCloseSidebarPopupTimer();
	}, [clearOpenSidebarPopupTimer, clearCloseSidebarPopupTimer]);

	/**
	 * Immediately force close all popups when this is called.
	 * Called when the popup is clicked on or when the page is redirected
	 */
	const closeAllHoverPageCards = useCallback(() => {
		clearHoverPageCardTimers();
		setShowHoverPageCardForItem({ itemID: null, trigger: null });
		experienceTracker.abort({
			name: SPACE_VIEWS_HOVER_PAGE_CARD_EXPERIENCE,
			reason: 'Popup closed',
		});
	}, [experienceTracker, clearHoverPageCardTimers]);

	/**
	 * On mouseleave of the hover target, start close timer for that item's hover card
	 */
	const onLeaveHoverTarget = useCallback(() => {
		startCloseHoverPageCardTimer();
	}, [startCloseHoverPageCardTimer]);

	/**
	 * On blur of the hover target, start close timer for that item's hover card
	 */
	const onBlurHoverTarget = useCallback(
		(e) => {
			if (!e.currentTarget.contains(e.relatedTarget)) {
				startCloseHoverPageCardTimer();
			}
		},
		[startCloseHoverPageCardTimer],
	);

	return {
		closeAllHoverPageCards,
		startCloseHoverPageCardTimer,
		clearHoverPageCardTimers,
		showHoverPageCardForItem,
		isHoverPageCardOptedIn,
		onEnterHoverTarget,
		onLeaveHoverTarget,
		onBlurHoverTarget,
	};
};
