import { useCallback, useContext } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useFeatureDiscovery } from '@confluence/feature-discovery';
import { useSessionData } from '@confluence/session-data';
import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';
import { SPAViewContext } from '@confluence/spa-view-context';

import { useTrialDetails } from './useTrialDetails';

export enum PremiumFeatureKey {
	ANALYTICS = 'analytics-used',
	AUTOMATION = 'automation-used',
	ADVANCED_PERMISSIONS = 'advanced-permissions-used',
	TEAM_CALENDAR = 'team-calendar-used',
	UNLIMITED_STORAGE = 'unlimited-storage-used',
}

export enum PremiumFeatureUsage {
	ANALYTICS_VIEW_SPACE = 'analyticsViewSpace',
	ANALYTICS_VIEW_PAGE = 'analyticsViewPage',
	AUTOMATION_NEW_RULE = 'automationNewRule',
	PERMISSIONS_INSPECT_PAGE = 'permissionsInspectPage',
	TEAM_CALENDAR_CREATE = 'teamCalendarCreate',
	TEAM_CALENDAR_VIEW = 'teamCalendarView',
}

const PremiumFeatureUsage2FeatureDiscoveryKey = {
	[PremiumFeatureUsage.ANALYTICS_VIEW_SPACE]: PremiumFeatureKey.ANALYTICS,
	[PremiumFeatureUsage.ANALYTICS_VIEW_PAGE]: PremiumFeatureKey.ANALYTICS,
	[PremiumFeatureUsage.AUTOMATION_NEW_RULE]: PremiumFeatureKey.AUTOMATION,
	[PremiumFeatureUsage.PERMISSIONS_INSPECT_PAGE]: PremiumFeatureKey.ADVANCED_PERMISSIONS,
	[PremiumFeatureUsage.TEAM_CALENDAR_CREATE]: PremiumFeatureKey.TEAM_CALENDAR,
	[PremiumFeatureUsage.TEAM_CALENDAR_VIEW]: PremiumFeatureKey.TEAM_CALENDAR,
};

export const useTrackPremiumFeatureUsage = (
	premiumFeatureUsage: PremiumFeatureUsage,
	skip: boolean = false,
) => {
	const { edition, activationId } = useSessionData();
	const { isSiteAdmin } = useContext(SPAViewContext);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { trialDaysLeft } = useTrialDetails({
		skip,
	});

	const isEligibleToMark = edition === ConfluenceEdition.PREMIUM && trialDaysLeft > 0;

	const [, markFeature] = useFeatureDiscovery(
		PremiumFeatureUsage2FeatureDiscoveryKey[premiumFeatureUsage],
		/* skip */ !isEligibleToMark,
	);

	const markFeatureAsUsed = useCallback(() => {
		markFeature();
		if (isEligibleToMark) {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'used',
					actionSubject: 'premiumFeature',
					source: premiumFeatureUsage,
					attributes: {
						activationId,
						isSiteAdmin,
					},
				},
			}).fire();
		}
	}, [
		activationId,
		createAnalyticsEvent,
		isEligibleToMark,
		isSiteAdmin,
		markFeature,
		premiumFeatureUsage,
	]);

	return {
		markFeatureAsUsed,
	};
};
