import { query } from '@confluence/query-preloader-tools';

import { SpaceBlogsQuery } from './SpaceBlogsQuery.graphql';
import { SpaceBlogsAppearancePersistenceQuery } from './SpaceBlogsPersistenceQueries.graphql';

export const preloadSpaceBlogs = ({ spaceKey }: { spaceKey: string }) => {
	return query({
		query: SpaceBlogsQuery,
		variables: {
			first: 24,
			spaceKey,
			statuses: ['current', 'draft'],
		},
	});
};

export const preloadSpaceBlogsAppearance = async ({
	spaceKey,
	isLoggedIn,
}: {
	spaceKey: string;
	isLoggedIn?: boolean;
}) => {
	if (isLoggedIn) {
		return query({
			query: SpaceBlogsAppearancePersistenceQuery,
			variables: { spaceKey },
		});
	}
};
