import gql from 'graphql-tag';

import { ContentStarFragment } from './ActionButtonFragments.fragment';

export const PageStarMutation = gql`
	mutation ActionButtonPageStar($id: ID!) {
		favouritePage(favouritePageInput: { pageId: $id }) {
			content {
				...ActionButtonContentStarFragment
			}
		}
	}
	${ContentStarFragment}
`;

export const PageUnstarMutation = gql`
	mutation ActionButtonPageUnstar($id: ID!) {
		unfavouritePage(favouritePageInput: { pageId: $id }) {
			content {
				...ActionButtonContentStarFragment
			}
		}
	}
	${ContentStarFragment}
`;

export const SpaceStarMutation = gql`
	mutation FavoriteSpaceMutation($spaceKey: String!) {
		favouriteSpace(spaceKey: $spaceKey) {
			isSpaceFavourited
		}
	}
`;

export const SpaceUnstarMutation = gql`
	mutation UnfavoriteSpaceMutation($spaceKey: String!) {
		unfavouriteSpace(spaceKey: $spaceKey) {
			isSpaceFavourited
		}
	}
`;

export const SpaceWatchMutation = gql`
	mutation SpaceDirectoryWatchSpaceMutation($spaceId: ID!) {
		watchSpace(watchSpaceInput: { spaceId: $spaceId, currentUser: true }) {
			space {
				id
				currentUser {
					isWatched
				}
			}
		}
	}
`;

export const SpaceUnwatchMutation = gql`
	mutation SpaceDirectoryUnWatchSpaceMutation($spaceId: ID!) {
		unwatchSpace(watchSpaceInput: { spaceId: $spaceId, currentUser: true }) {
			space {
				id
				currentUser {
					isWatched
				}
			}
		}
	}
`;
