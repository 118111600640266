/** @jsx jsx */
import type { FC } from 'react';
import React, { useLayoutEffect } from 'react';
import { styled, keyframes, css, jsx } from '@compiled/react';

import { B50, B400, N20, N30, N500, R400, N70 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import type { DraggableState, DragHandleProps } from '@confluence/tree';
import { isDraggingBodyClass } from '@confluence/tree';
import { useIsFolderContentViewEnabled } from '@confluence/folder-utils/entry-points/useIsFolderContentViewEnabled';

import type { ContentTreeItem } from './data-transformers';

const HIGHLIGHT_FADE = keyframes({
	from: {
		background: token('color.background.brand.bold', B50),
	},
	to: {
		background: 'none',
	},
});

const notchColorVar = '--notch-color';

const notchStyles = css({
	position: 'absolute',
	insetBlockStart: '50%',
	insetInlineStart: token('space.0', '0px'),
	width: '2px',
	height: '12px',
	transform: 'translateY(-50%)',
	backgroundColor: `var(${notchColorVar})`,
});

const getElementBeforeColor = (
	draggableState: DraggableState | undefined,
	isNav4Enabled: boolean | undefined,
) => {
	if (draggableState === 'dragging') {
		return token('color.icon.disabled', N70);
	}
	if (isNav4Enabled) {
		return token('color.icon', N500);
	}
	return token('color.icon.accent.gray', '#626f86');
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ElementBefore = styled.span<{
	isSelected: boolean;
	draggableState?: DraggableState;
	isNav4Enabled?: boolean;
}>({
	zIndex: 1, // position above the reparent background
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	marginRight: ({ isNav4Enabled }) =>
		isNav4Enabled ? token('space.0', '0px') : token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	marginLeft: ({ isNav4Enabled }) =>
		isNav4Enabled ? token('space.050', '4px') : token('space.100', '8px'),
	display: 'flex',
	height: '24px',
	width: '24px',
	webkitBoxAlign: 'center',
	webkitBoxPack: 'center',
	alignItems: 'center',
	justifyContent: 'center',
	flexShrink: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: ({ draggableState, isNav4Enabled }) =>
		getElementBeforeColor(draggableState, isNav4Enabled),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ElementAfter = styled.span({
	display: 'flex',
	flexShrink: 0,
	marginLeft: token('space.150', '12px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LinkItemContent = styled.div({
	width: '100%',
	display: 'flex',
	minHeight: '24px',
	'-webkit-box-align': 'center',
	alignItems: 'center',
	margin: 0,
	padding: 0,
});

/**
 *  Use before element + z-index to create a pseudo background. There's no plan to indent the hover and active states
 *  on the main item. The indenting here is still important so that reparenting matches the indent of the "merge"
 *  outline + the drop indicator.
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReparentBackground = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':before': {
		position: 'absolute',
		zIndex: 0,
		content: `""`,
		backgroundColor: token('color.background.selected.hovered', B50),
		height: '100%',
		top: 0,
		left: 0,
		right: 0,
		borderRadius: '3px',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleSpan = styled.span({
	'-webkit-box-flex': 1,
	flexGrow: 1,
	textAlign: 'left',
	overflow: 'hidden',
	outline: 'none',
	display: 'flex',
	flexDirection: 'column',
	lineHeight: 1.22,
	zIndex: 1,
});

type PageTreeLinkProps = {
	item: ContentTreeItem;
	draggableState?: DraggableState;
	isSuperAdmin: boolean;
	indent?: number;
	forceHoverBackground: boolean;
	isSelected: boolean;
	isQuickActionsFocused: boolean;
	href: string | null;
	isNav4Enabled: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PageTreeLink = styled.a<PageTreeLinkProps>(
	/**
	 * Display
	 */
	{
		position: 'relative', // necessary for absolutely positioned reparent background
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		/**
		 * Content box
		 */
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
		width: ({ indent, isNav4Enabled }) => (isNav4Enabled ? `calc(100% - ${indent}px)` : '100%'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
		height: ({ isNav4Enabled }) => (isNav4Enabled ? '32px' : '36px'),
		minHeight: 24,
		boxSizing: 'border-box',
		WebkitBoxAlign: 'center',
		borderRadius: 3,
		border: 'none',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-dynamic-styles
		margin: ({ indent, isNav4Enabled }) => (isNav4Enabled ? `0 0 0 ${indent}px` : '0px !important'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		padding: ({ indent, isNav4Enabled }) => `0 4px 0 ${isNav4Enabled ? 0 : indent}px`,
		'&:active': {
			boxShadow: 'none',
			border: 'none',
		},
		'&:focus': {
			outline: 'none',
			boxShadow: `${token('color.border.selected', '#388BFF')} 0px 0px 0px 2px inset`,
		},
		'&:hover': {
			[notchColorVar]: token('color.background.neutral.hovered', N30),
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	({ isNav4Enabled }) =>
		// Allow the area to the left of the item to be clickable
		isNav4Enabled && {
			'&:before': {
				content: ' ',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
				width: ({ indent }) => `${indent}px`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
				marginLeft: ({ indent }) => `-${indent}px`,
				height: '24px',
			},
		},
	/**
	 * Background color
	 */
	{
		backgroundColor: 'transparent',
		[notchColorVar]: 'transparent',
		'&:hover, &:focus-within': {
			backgroundColor: token('color.background.neutral.subtle.hovered', N20),
		},
		'&:active, &:focus': {
			backgroundColor: token('color.background.selected', B50),
		},
		// force the background color to be transparent while ANY drag is ocurring
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		[`body.${isDraggingBodyClass} &:hover, body.${isDraggingBodyClass} &:focus-within`]: {
			backgroundColor: 'transparent',
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isSelected }) =>
		isSelected && {
			backgroundColor: token('color.background.selected', B50),
			[notchColorVar]: token('color.background.selected.bold'),
			'&:hover, &:focus-within': {
				backgroundColor: token('color.background.selected.hovered', '#cce0ff'),
				[notchColorVar]: token('color.background.selected.bold'),
			},
		},
	// when quick actions or hover page cards are open
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ forceHoverBackground }) =>
		forceHoverBackground && {
			backgroundColor: token('color.background.neutral.subtle.hovered', N20),
		},
	/**
	 * Text and color
	 */
	{
		fontSize: 14,
		userSelect: 'none',
		fontWeight: '400',
		textDecoration: 'none',
		color: token('color.text.subtle', N500),
		'&:visited, &:active, &:focus, &:hover': {
			color: token('color.text.subtle', N500),
			textDecoration: 'none',
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isSelected }) =>
		isSelected && {
			'&:visited, &:active, &:focus': {
				color: token('color.text.selected', '#0c66e4'),
			},
			'&:hover': {
				color: token('color.text.accent.blue', B400), // TODO: change this to B800 when it becomes available
			},
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isSuperAdmin, item }) =>
		isSuperAdmin &&
		(item.hasRestrictions || item.hasInheritedRestrictions) && {
			color: token('color.text.danger', R400),
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		props.draggableState === 'dragging' && {
			'& .title': {
				color: token('color.text.disabled', N70),
			},
		},
	/**
	 * After icon
	 */
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		'--display-after-icon': ({ forceHoverBackground, isQuickActionsFocused }) =>
			forceHoverBackground || isQuickActionsFocused ? 'flex' : 'none',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		'--opacity-after-icon': ({ forceHoverBackground, isQuickActionsFocused }) =>
			forceHoverBackground || isQuickActionsFocused ? '100%' : '0%',
		// show after icon when hover or focus-within on the link item
		'&:hover, &:focus-within': {
			'--display-after-icon': 'flex',
			'--opacity-after-icon': '100%',
		},
		// force hide after icon when drag is ocurring
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		[`body.${isDraggingBodyClass} &:hover, body.${isDraggingBodyClass} &:focus-within`]: {
			'--display-after-icon': 'none',
			'--opacity-after-icon': '0%',
		},
	},
	/**
	 * Drafts
	 */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ item }) =>
		item.data.status === 'DRAFT' && {
			'& .title': { opacity: '0.7' }, // Drafts should be 70% opacity
		},
	/**
	 * Highlight animation
	 */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ item }) =>
		item.data.isHighlighted && {
			animationName: HIGHLIGHT_FADE,
			animationDuration: '2s',
			animationTimingFunction: 'linear',
		},
	/**
	 * Folder icon styles
	 */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ item, draggableState }) =>
		item.data.type === 'folder' &&
		draggableState === 'dragging' && {
			"[data-component-selector='FolderIconSpan']": {
				backgroundColor: token('color.text.disabled', N70),
			},
		},
);

type PageTreeLinkItemProps = {
	dragHandleProps?: DragHandleProps;
	onMouseEnter(event: any): void;
	onMouseLeave(event: any): void;
	onBlur(event: any): void;
	onFocus(event: any): void;
	onClick(event: any): void;
	beforeIcon: React.ReactNode;
	afterIcon: React.ReactNode;
	children?: React.ReactNode;
} & PageTreeLinkProps;

export const focusedAriaCurrentValue = 'page';

const scrollOptions: ScrollIntoViewOptions = {
	behavior: 'auto',
	block: 'center',
	inline: 'nearest',
};

// there should be a valid calculated layout for `scrollIntoView`
// `requestAnimationFrame` allows to execute the scroll right after the layout is calculate
// without it `scrollIntoView` will force the sync layout calculation, which has a negative performance impact
export const ssrInlineScriptContent = `requestAnimationFrame(function() {
  const selected = document.querySelector('[aria-current="${focusedAriaCurrentValue}"]');
  if(selected) selected.scrollIntoView(${JSON.stringify(scrollOptions)});
  });
  `;

/**
 * This needs to be placed at the end of side navigation.
 * Because we need to execute it synchronously to allow scroll to be fast.
 * But we can only execute when everything is parsed in the side bar to get the correct scroll position.
 * Also this script is executed as inline script in SSR result as well as in SPA code to avoid jumping.
 *
 * When blog_tree_under_content_tree statsig gate is on and blog tree is showing, it will also use this exact
 * script to scroll to the selected blog tree item. We apply the same focusedAriaCurrentValue to the selected blog tree item.
 */
export const FocusToCurrentPageTreeLinkItemSSRInlineScript = () => {
	useLayoutEffect(() => {
		if (window.__SSR_RENDERED__) {
			const script = document.createElement('script');
			script.appendChild(document.createTextNode(ssrInlineScriptContent));
			document.body.appendChild(script);
			return () => {
				document.body.removeChild(script);
			};
		}
	}, []);

	if (process.env.REACT_SSR) {
		return (
			<script
				/* eslint-disable-next-line react/no-danger */
				dangerouslySetInnerHTML={{
					__html: ssrInlineScriptContent,
				}}
			/>
		);
	}
	return null;
};

export const PageTreeLinkItem: FC<PageTreeLinkItemProps> = ({
	afterIcon,
	beforeIcon,
	children,
	draggableState,
	dragHandleProps,
	indent,
	isSelected,
	href,
	isNav4Enabled,
	...passThroughPageTreeLinkProps
}) => {
	const { isFolderContentViewEnabled } = useIsFolderContentViewEnabled();
	const isFolder = passThroughPageTreeLinkProps.item.data.type === 'folder';

	return (
		<PageTreeLink
			{...dragHandleProps}
			{...passThroughPageTreeLinkProps}
			// here we must pass in a href prop otherwise the link will be rendered as a button
			// This will break the page as inside the link there is another <button>
			// <button> can't be rendered inside another <button>
			href={(!isFolderContentViewEnabled && isFolder) || !href ? '#' : href}
			aria-current={isSelected ? focusedAriaCurrentValue : undefined}
			data-testid="page-tree-item"
			draggableState={draggableState}
			indent={indent}
			isSelected={isSelected}
			isNav4Enabled={isNav4Enabled}
		>
			<LinkItemContent>
				{isNav4Enabled && <div css={notchStyles} aria-hidden="true" />}
				<ElementBefore
					isSelected={isSelected}
					draggableState={draggableState}
					isNav4Enabled={isNav4Enabled}
				>
					{beforeIcon}
				</ElementBefore>
				<TitleSpan>{children}</TitleSpan>
				<ElementAfter>{afterIcon}</ElementAfter>
			</LinkItemContent>
			{draggableState === 'parent-of-instruction' ? (
				<ReparentBackground data-testid="reparent-background" />
			) : null}
		</PageTreeLink>
	);
};
