import { AccessStatus, useSessionData } from '@confluence/session-data';

export interface IsExternalCollaboratorDataType {
	isExternalCollaborator: boolean;
}

export const useIsExternalCollaborator = (): IsExternalCollaboratorDataType => {
	const { accessStatus } = useSessionData();

	return {
		isExternalCollaborator: accessStatus === AccessStatus.EXTERNAL_COLLABORATOR_ACCESS,
	};
};
