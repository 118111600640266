import type { ComponentType } from 'react';

import { componentWithCondition } from '@confluence/feature-gating-utils';
import { fg } from '@confluence/feature-gating';

export const componentWithFG = <A extends {}, B extends {}>(
	gateName: string,
	ComponentTrue: ComponentType<A>,
	ComponentFalse: ComponentType<B>,
	// eslint-disable-next-line confluence-feature-gating/static-feature-gates
) => componentWithCondition(() => fg(gateName), ComponentTrue, ComponentFalse);
