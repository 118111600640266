import { useCallback } from 'react';
import { defineMessages } from 'react-intl-next';
import { useQuery, useMutation } from '@apollo/react-hooks';

import { getApolloClient } from '@confluence/graphql';

import { PersistenceQuery, PersistenceMutation } from './SpaceViewsPersistenceQueries.graphql';
import { SpaceViewsPersistenceOption as SpaceViewsSort } from './__types__/PersistenceQuery';
import type {
	SpaceViewsPersistenceQuery as PersistenceQueryData,
	SpaceViewsPersistenceQueryVariables as PersistenceQueryVars,
} from './__types__/PersistenceQuery';
import type {
	SpaceViewsPersistenceMutation as PersistenceMutationData,
	SpaceViewsPersistenceMutationVariables as PersistenceMutationVars,
} from './__types__/PersistenceMutation';

export { SpaceViewsPersistenceOption as SpaceViewsSort } from './__types__/PersistenceQuery';

export const sortI18n = defineMessages({
	[SpaceViewsSort.TREE]: {
		id: 'space-views.tree',
		defaultMessage: 'Tree',
		description: 'dropdown menu value for the default tree view',
	},
	[SpaceViewsSort.RECENTLY_VIEWED]: {
		id: 'space-views.recently.viewed',
		defaultMessage: 'My visits',
		description:
			'dropdown menu value for filtering by only pages the current viewer has recently viewed',
	},
	[SpaceViewsSort.RECENTLY_MODIFIED]: {
		id: 'space-views.recently.modified.nonfinal', // Remove .nonfinal suffix when copy is finalized
		defaultMessage: 'Last updated',
		description:
			'dropdown menu value for sorting by pages in the order that they were most recently modified',
	},
	[SpaceViewsSort.TITLE_AZ]: {
		id: 'space-views.a-z.desc',
		defaultMessage: 'Title - A to Z',
		description: 'dropdown menu value for sorting by title from A-Z',
	},
});

export const useSpaceViewsOptions = (spaceKey: string, isLicensed = false) => {
	const {
		data,
		loading: queryLoading,
		error: queryError,
	} = useQuery<PersistenceQueryData, PersistenceQueryVars>(PersistenceQuery, {
		variables: { spaceKey },
		skip: !isLicensed,
	});

	const [updatePersistence, { loading: mutationLoading, error: mutationError }] = useMutation<
		PersistenceMutationData,
		PersistenceMutationVars
	>(PersistenceMutation);

	const setSpaceViewsSort = useCallback(
		(newSort: SpaceViewsSort) => {
			updatePersistence({
				variables: {
					spaceKey,
					persistenceOption: newSort,
				},
			}).catch(() => {}); // ignoring errors here because we are consuming error from useMutation

			// Update the cache to keep UI updated regardless of whether the mutation succeeds
			getApolloClient().writeQuery({
				query: PersistenceQuery,
				variables: { spaceKey },
				data: {
					userPreferences: {
						spaceViewsPersistence: newSort,
						__typename: 'UserPreferences',
					},
				},
			});
		},
		[spaceKey, updatePersistence],
	);

	const spaceViewsSort: SpaceViewsSort =
		data?.userPreferences?.spaceViewsPersistence || SpaceViewsSort.TREE; //tree is default

	return {
		spaceViewsSort,
		setSpaceViewsSort,
		queryLoading,
		queryError,
		mutationLoading,
		mutationError,
	};
};
