import { useContext, useRef } from 'react';

import type { ExperienceAttributes, ExperienceEvent } from '../ExperienceEvent';
import type { Experience } from '../Experience';

import { ExperienceTrackerContext } from './ExperienceTrackerContext';

export type ExperienceStartProps = {
	name: string;
	timeout?: number;
	attributes?: ExperienceAttributes;
	id?: string;
	collect?(events: ExperienceEvent[], experience: Experience): void;
	onSuccess?(): void;
};

export function ExperienceStart({
	id,
	name,
	attributes,
	collect,
	onSuccess,
}: ExperienceStartProps) {
	const experienceTracker = useContext(ExperienceTrackerContext);
	const wasCalledRef = useRef<boolean>(false);

	if (!wasCalledRef.current) {
		experienceTracker.start({
			attributes,
			name,
			id,
			collect,
			onSuccess,
		});
		wasCalledRef.current = true;
	}

	return null;
}
