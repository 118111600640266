import gql from 'graphql-tag';

/* This is experiment code. CC-FE does not encourage query usage in reusable hook
 * Once productionized, query will be merged into its actual component.
 */

export const TrialDetailsQuery = gql`
	query TrialDetailsQuery {
		license {
			trialEndDate
			billingSourceSystem
		}
	}
`;
