export const PAGE_TREE_ITEM_CLICK = 'PAGE_TREE_ITEM_CLICK';
export const SMARTLINK_CLICK = 'SMARTLINK_CLICK';
export const RECENT_DROPDOWN_ITEM_CLICK = 'RECENT_DROPDOWN_ITEM_CLICK';
export const ADVANCED_SEARCH_ITEM_CLICK = 'ADVANCED_SEARCH_ITEM_CLICK';
export const EDITOR_UPDATE_BUTTON_CLICK = 'EDITOR_UPDATE_BUTTON_CLICK';
export const EDITOR_UPDATE_SHORTCUT_KEY_PRESS = 'EDITOR_UPDATE_SHORTCUT_KEY_PRESS';
export const EDITOR_UPDATE_BUTTON_IN_MODAL_CLICK = 'EDITOR_UPDATE_BUTTON_IN_MODAL_CLICK';
export const EDITOR_UPDATE_SHORTCUT_IN_MODAL_PRESS = 'EDITOR_UPDATE_SHORTCUT_IN_MODAL_PRESS';
export const EDITOR_CLOSE_BUTTON_CLICK = 'EDITOR_CLOSE_BUTTON_CLICK';
export const RECENT_WORK_ITEM_ON_HOME_PAGE_CLICK = 'RECENT_WORK_ITEM_ON_HOME_PAGE_CLICK';
export const DISCOVER_ITEM_ON_HOME_PAGE_CLICK = 'DISCOVER_ITEM_ON_HOME_PAGE_CLICK';
export const RELATED_PAGES_ITEM_CLICK = 'RELATED_PAGES_ITEM_CLICK';
export const UNKNOWN = 'UNKNOWN';
