import React, { forwardRef, type Ref, useEffect, useRef } from 'react';

import type { RouterLinkComponentProps } from '@atlaskit/app-provider';

import { MenuItemBase } from './menu-item';
import { MenuListItem } from './menu-list-item';
import type { MenuItemCommonProps } from './types';

export type MenuLinkItemProps<RouterLinkConfig extends Record<string, any> = never> =
	MenuItemCommonProps &
		RouterLinkComponentProps<RouterLinkConfig> & {
			target?: HTMLAnchorElement['target'];
			isSelected?: boolean;
		};

const MenuLinkItemNoRef = <RouterLinkConfig extends Record<string, any> = never>(
	{
		testId,
		actions,
		children,
		description,
		elemAfter,
		elemBefore,
		href,
		target,
		actionsOnHover,
		isSelected,
		onClick,
		interactionName,
		isContentTooltipDisabled,
	}: MenuLinkItemProps<RouterLinkConfig>,
	forwardedRef?: Ref<HTMLAnchorElement>,
) => {
	const itemRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		// JSDOM does not support scrollIntoView, so ensuring it exists first (see https://github.com/jsdom/jsdom/issues/1695)
		if (isSelected && itemRef.current?.scrollIntoView) {
			itemRef.current.scrollIntoView({
				block: 'nearest',
			});
		}
		// This effect should only run on initial mount, otherwise the sidebar would scroll every time an item is selected
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<MenuListItem ref={itemRef}>
			<MenuItemBase
				testId={testId}
				description={description}
				actions={actions}
				elemAfter={elemAfter}
				// TODO Always use "open in new window" icon when `openInNewWindow` prop is true
				elemBefore={elemBefore}
				href={href}
				target={target}
				actionsOnHover={actionsOnHover}
				isSelected={isSelected}
				onClick={onClick}
				ref={forwardedRef}
				interactionName={interactionName}
				isContentTooltipDisabled={isContentTooltipDisabled}
			>
				{children}
			</MenuItemBase>
		</MenuListItem>
	);
};

// Workaround to support generic types with forwardRef
/**
 * __MenuLinkItem__
 *
 * A menu item link. It should be used within a `ul`.
 */
export const MenuLinkItem = forwardRef(MenuLinkItemNoRef) as <
	RouterLinkConfig extends Record<string, any> = never,
>(
	props: MenuLinkItemProps<RouterLinkConfig> & {
		ref?: Ref<HTMLAnchorElement>;
	},
) => ReturnType<typeof MenuLinkItemNoRef>;
