import type {
	SitePermissionType,
	ExternalCollaboratorQuery,
} from './__types__/ExternalCollaboratorQuery';

export const getUserPermissionFromQuery = (
	queryData: ExternalCollaboratorQuery | null | undefined,
): SitePermissionType | null | undefined => {
	return queryData?.user?.confluence?.permissionType;
};
