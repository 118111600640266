import gql from 'graphql-tag';

export const Nav4ChangeboardingQuery = gql`
	query Nav4ChangeboardingQuery($userId: String!) @experimental {
		experimentalUserPersonalization(userId: $userId) {
			attributes {
				name
				value
				__typename
			}
			__typename
		}
	}
`;
