import gql from 'graphql-tag';

export const SpaceBlogsQuery = gql`
	query SpaceBlogs($spaceKey: String!, $first: Int, $statuses: [String], $after: String) {
		content(
			spaceKey: $spaceKey
			type: "blogpost"
			first: $first
			orderby: "history.createdDate desc"
			after: $after
			status: $statuses
		) {
			pageInfo {
				hasNextPage
				endCursor
			}
			count
			nodes {
				id
				title
				type
				status
				blank
				editorVersion: properties(key: "editor") {
					nodes {
						id
						key
						value
					}
				}
				properties(keys: ["emoji-title-published", "emoji-title-draft"]) {
					nodes {
						id
						key
						value
					}
				}
				excerpt
				version {
					by {
						profilePicture {
							path
						}
						displayName
						... on KnownUser {
							accountId
						}
						... on User {
							accountId
						}
					}
				}
				history {
					createdDate
					createdBy {
						profilePicture {
							path
						}
						displayName
						... on KnownUser {
							accountId
						}
						... on User {
							accountId
						}
					}
				}
				links {
					webui
					editui
				}
				likes {
					count
				}
				metadata {
					lastModifiedDate
					currentuser {
						favourited {
							isFavourite
						}
					}
					comments {
						commentsCount
					}
				}
			}
		}
	}
`;
