import { LoadableLazy } from '@confluence/loadable';

export const EditSpaceLogo = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-EditSpaceLogoEditSpaceLogo" */ './EditSpaceLogo/EditSpaceLogo'
			)
		).EditSpaceLogo,
});
