/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum SpaceViewsPersistenceOption {
  POPULARITY = "POPULARITY",
  RECENTLY_MODIFIED = "RECENTLY_MODIFIED",
  RECENTLY_VIEWED = "RECENTLY_VIEWED",
  TITLE_AZ = "TITLE_AZ",
  TREE = "TREE",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SpaceViewsPersistenceQuery
// ====================================================

export interface SpaceViewsPersistenceQuery_userPreferences {
  spaceViewsPersistence: SpaceViewsPersistenceOption;
}

export interface SpaceViewsPersistenceQuery {
  userPreferences: SpaceViewsPersistenceQuery_userPreferences;
}

export interface SpaceViewsPersistenceQueryVariables {
  spaceKey: string;
}
