import { useCallback, useContext, useEffect } from 'react';
import type { ApolloError } from 'apollo-client';

import {
	SPACE_VIEWS_PAGINATE_EXPERIENCE,
	SPACE_VIEWS_CHANGE_EXPERIENCE,
	SPACE_VIEWS_INITIAL_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import type { ExperienceTrackerAPI, ExperienceName } from '@confluence/experience-tracker';

import type { SpaceViewsSort } from './useSpaceViewsOptions';

type ExperienceTrackingParams = {
	loading: boolean;
	error: ApolloError | undefined;
	persistenceError: ApolloError | undefined;
	persistenceUpdateLoading: boolean;
	persistenceUpdateError: ApolloError | undefined;
	spaceViewsSort: SpaceViewsSort;
	setSpaceViewsSort: (newSort: SpaceViewsSort) => void;
};

const stopOrSucceed = (
	name: ExperienceName,
	attributes: Record<string, any>,
	error: ApolloError | undefined,
	experienceTracker: ExperienceTrackerAPI,
) => {
	if (error) {
		experienceTracker.stopOnError({ name, attributes, error });
	} else {
		experienceTracker.succeed({ name, attributes });
	}
};

export const useSpaceViewsExperienceTracking = ({
	loading,
	error,
	persistenceError,
	persistenceUpdateLoading,
	persistenceUpdateError,
	spaceViewsSort,
	setSpaceViewsSort,
}: ExperienceTrackingParams) => {
	const experienceTracker = useContext(ExperienceTrackerContext);

	const makeTrackedFetchMore = useCallback(
		(fetchMore: any) => () => {
			experienceTracker.start({ name: SPACE_VIEWS_PAGINATE_EXPERIENCE });
			fetchMore();
		},
		[experienceTracker],
	);

	const trackedSetSpaceViewsSort = useCallback(
		(newSort: SpaceViewsSort) => {
			experienceTracker.start({
				name: SPACE_VIEWS_CHANGE_EXPERIENCE,
				attributes: { prevSort: spaceViewsSort },
			});
			setSpaceViewsSort(newSort);
		},
		[experienceTracker, spaceViewsSort, setSpaceViewsSort],
	);

	useEffect(() => {
		experienceTracker.start({ name: SPACE_VIEWS_INITIAL_EXPERIENCE });
	}, [experienceTracker]);

	useEffect(() => {
		if (!loading && !persistenceUpdateLoading) {
			const attributes = { spaceViewsSort };

			stopOrSucceed(
				SPACE_VIEWS_INITIAL_EXPERIENCE,
				attributes,
				error || persistenceError,
				experienceTracker,
			);
			stopOrSucceed(
				SPACE_VIEWS_CHANGE_EXPERIENCE,
				attributes,
				error || persistenceUpdateError,
				experienceTracker,
			);
			stopOrSucceed(SPACE_VIEWS_PAGINATE_EXPERIENCE, attributes, error, experienceTracker);
		}
	}, [
		experienceTracker,
		loading,
		error,
		persistenceError,
		persistenceUpdateLoading,
		persistenceUpdateError,
		spaceViewsSort,
	]);

	return { makeTrackedFetchMore, trackedSetSpaceViewsSort };
};
