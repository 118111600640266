import { useQuery } from '@apollo/react-hooks';

import { useSessionData } from '@confluence/session-data';

import { HoverPageCardsOptStatusQuery } from './HoverPageCardsOptStatusQuery.graphql';
import type { HoverPageCardsOptStatusQuery as HoverPageCardsOptStatusQueryType } from './__types__/HoverPageCardsOptStatusQuery';
import { PageCardInPageTreeHoverPreference } from './__types__/HoverPageCardsOptStatusQuery';

/**
 * @returns
 * - isHoverPageCardOptedIn: boolean - true if user is opted into hover page cards, false if we want to render tooltips instead
 */
export const useHoverPageCardsOptStatus = () => {
	const { isLicensed } = useSessionData();

	const { data } = useQuery<HoverPageCardsOptStatusQueryType>(HoverPageCardsOptStatusQuery, {
		skip: !isLicensed,
	});

	// don't render popups for anon users.
	// This is after the useQuery because conditionally running hooks isn't allowed.
	if (!isLicensed) {
		return {
			isHoverPageCardOptedIn: false,
		};
	}

	const userPreference = data?.userPreferences?.shouldShowCardOnPageTreeHover;

	return {
		isHoverPageCardOptedIn: userPreference !== PageCardInPageTreeHoverPreference.NO_SHOW_PAGECARD,
	};
};
