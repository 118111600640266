import React from 'react';

import LegacyUnstarredIcon from '@atlaskit/icon/glyph/star';
import { StarSelectedIcon as LegacyStarredIcon } from '@confluence/icons/entry-points/StarSelectedIcon';

import StarredIcon from '@atlaskit/icon/core/star-starred';
import UnstarredIcon from '@atlaskit/icon/core/star-unstarred';

import { token, useThemeObserver } from '@atlaskit/tokens';
import { Y300 } from '@atlaskit/theme/colors';

type StarIconProps = {
	isStarred: boolean;
	size?: 'small' | 'medium';
	legacy?: boolean;
};

export const StarIcon = ({ isStarred, size = 'medium', legacy = false }: StarIconProps) => {
	const theme = useThemeObserver();
	const starredColor =
		theme.colorMode === 'dark'
			? token('color.icon.accent.yellow', Y300)
			: token('color.icon.accent.orange', '#E56910');

	if (legacy) {
		return isStarred ? (
			<LegacyStarredIcon label="" size={size} />
		) : (
			<LegacyUnstarredIcon label="" size={size} />
		);
	}

	return isStarred ? (
		<StarredIcon label="" color={starredColor} LEGACY_size={size} />
	) : (
		<UnstarredIcon label="" color="currentColor" LEGACY_size={size} />
	);
};
