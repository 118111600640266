import gql from 'graphql-tag';

export const ContentStarFragment = gql`
	fragment ActionButtonContentStarFragment on Content {
		id
		metadata {
			currentuser {
				favourited {
					isFavourite
					favouritedDate
				}
			}
		}
	}
`;

export const SpaceStarFragment = gql`
	fragment ActionButtonSpaceFragment on Space {
		currentUser {
			isFavourited
		}
	}
`;
