import gql from 'graphql-tag';

import { UserPermissionFragment } from '@confluence/external-collab-ui/entry-points/UserPermissionFragment';

export const SpaceNavigationQuery = gql`
	query SpaceNavigationQuery($spaceKey: String, $isLicensed: Boolean!) {
		...UserPermissionFragment
		spaceSidebarLinks(spaceKey: $spaceKey) {
			main(includeHidden: true) {
				id
				webItemKey
				webItemCompleteKey
				title
				url
				position
				styleClass
				icon {
					path
					width
					height
					isDefault
				}
				iconClass
				hidden
				canHide
				tooltip
				linkIdentifier
				type
			}
			quick {
				id
				url
				position
				title
				styleClass
			}
		}
		space(key: $spaceKey) {
			id
			key
			name
			type
			status
			icon {
				path
			}
			homepage {
				id
			}
			currentUser @include(if: $isLicensed) {
				isAdmin
				isFavourited
				isWatched
			}
			operations {
				operation
				targetType
			}
			containsExternalCollaborators
			history {
				createdDate
			}
		}
	}

	${UserPermissionFragment}
`;
