import { useQuery } from 'react-apollo';
import { differenceInDays } from 'date-fns';

import { isGraphQLError } from '@confluence/network';
import { isErrorMarkedAsHandled, markErrorAsHandled } from '@confluence/graphql';
import { useSessionData } from '@confluence/session-data';

import { TrialDetailsQuery } from './TrialDetailsQuery.graphql';
import type { TrialDetailsQuery as TrialDetailsQueryType } from './__types__/TrialDetailsQuery';

type UseTrialDetailsProps = {
	skip?: boolean;
};
/**
 * Productionization note:
 * this is copied from next/packages/trial-components/src/graphql/getTrialDaysLeftFromQuery.ts
 * revisit this when productionize
 */
const getTrialDaysLeft = (trialEndDate: string | null | undefined) => {
	if (!trialEndDate) {
		return 0;
	}

	// +1 day to round up for fractional days
	return differenceInDays(new Date(trialEndDate), new Date()) + 1;
};

export const useTrialDetails = ({ skip = true }: UseTrialDetailsProps) => {
	const { isLoggedIn } = useSessionData();

	const shouldSkipQuery = skip || !isLoggedIn;
	const {
		data: trialData,
		loading: trialDetailsLoading,
		error: trialDetailsError,
	} = useQuery<TrialDetailsQueryType>(TrialDetailsQuery, {
		skip: shouldSkipQuery,
	});

	if (isGraphQLError(trialDetailsError) && !isErrorMarkedAsHandled(trialDetailsError)) {
		markErrorAsHandled(trialDetailsError);
	}

	const trialDaysLeft = getTrialDaysLeft(trialData?.license?.trialEndDate);

	return {
		trialData,
		trialDetailsLoading,
		trialDetailsError,
		isInstanceInTrial: trialDaysLeft > 0,
		trialDaysLeft,
		trialEndDate: trialData?.license?.trialEndDate,
		billingSourceSystem: trialData?.license?.billingSourceSystem,
	};
};
