/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import React from 'react';

import { cssMap, jsx } from '@compiled/react';

import type { StrictXCSSProp } from '@atlaskit/css';

// These CSS variables are "compressed" to discourage direct usage.
// Using `opacity` so that the element is still in the document flow while hidden, so that it can be part of the accessibilty tree
// when the surface is not interacted with. Use case: keeping the element in the tab order.
const opacityCssVar = '--n_sfc_o';
// `display` is used when the element should be removed from the document flow when hidden.
const displayCssVar = '--n_sfc_d';

const surfaceStyles = cssMap({
	root: {
		[opacityCssVar]: '0%',
		[displayCssVar]: 'none',
		'&:hover, &:focus-within': {
			[opacityCssVar]: '100%', // Using percentages instead of number as Compiled will convert `1` to `1px`.
			[displayCssVar]: 'block',
		},
	},
});

/**
 * __InteractionSurface__
 *
 * The parent interaction surface that applies styles to its `InteractionConsumer` children
 * when interacted with (focus, hover).
 */
export const InteractionSurface = ({
	children,
	xcss,
}: {
	children: React.ReactNode;
	xcss?: StrictXCSSProp<'width' | 'height', never>;
}) => (
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop
	<div css={surfaceStyles.root} className={xcss}>
		{children}
	</div>
);

const behaviorStyles = cssMap({
	hideOnLeave: {
		// Opacity is used to keep the element within the document flow, so that it maintains its tab order.
		opacity: `var(${opacityCssVar})`,
	},
	removeOnLeave: {
		display: `var(${displayCssVar})`,
	},
});

/**
 * InteractionConsumer
 *
 * The child of an `InteractionSurface` that is styled based on interactions made to the surface.
 *
 * Note: if this is enabled, the element will still be in the document flow and will take up space.
 */
export const InteractionConsumer = ({
	children,
	testId,
	xcss,
	behavior = 'hideOnLeave',
}: {
	children: React.ReactNode;
	testId?: string;
	xcss?: StrictXCSSProp<'backgroundColor' | 'gridRow' | 'gridColumn', never>;
	/**
	 * The behavior of the element.
	 * - `hideOnLeave`: When the parent surface is not hovered/focused, the element will be hidden using `opacity`, but still be part of
	 * the document flow and take up space. This is useful when the element needs to be part of the accessibility tree and keep its tab order.
	 * - `removeOnLeave`: When the parent surface is not hovered/focused, the element will be hidden using `display`, and will not be
	 * part of the document flow and won't take up space.
	 * - `alwaysShown` - The element will always be shown, regardless of interaction with the parent `InteractionSurface`.
	 */
	behavior?: 'hideOnLeave' | 'removeOnLeave' | 'alwaysShown';
}) => (
	<div
		css={[
			behavior === 'hideOnLeave' && behaviorStyles.hideOnLeave,
			behavior === 'removeOnLeave' && behaviorStyles.removeOnLeave,
		]}
		data-testid={testId}
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop
		className={xcss}
	>
		{children}
	</div>
);
